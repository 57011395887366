import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios"
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('traitement.sortie_stock.page_titre'),
            point_de_ventes_id: "",
            point_de_ventes: "",
            montant_total: "",
            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'date_sortie_stock_formatted',
                        title: this.$t('traitement.sortie_stock.date'),
                        sortField: 'date_sortie_stock',
                        dataClass: 'text-center',
                        width: "100px",
                    },
                    {
                        name: 'nom_magasin',
                        title: this.$t('traitement.sortie_stock.magasin'),
                        dataClass: 'text-left',
                        sortField: 'nom_magasin',
                    },
                    {
                        name: 'commentaire',
                        title: this.$t('traitement.sortie_stock.commentaire'),
                        dataClass: 'text-left',
                        sortField: 'commentaire',
                    },
                    {
                        name: 'total_ttc',
                        title: this.$t('traitement.sortie_stock.valeur'),
                        dataClass: 'text-right',
                        sortField: 'total_ttc',
                        titleClass: 'text-center',
                        width: "150px",
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: this.$t('traitement.sortie_stock.date_enregistrement'),
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        sortField: 'date_heure_enregistrement',
                        width: "150px",
                    },

                    {
                        name: 'actions',
                        width: "80px",
                        dataClass: "text-center",
                        titleClass: 'text-center',
                    }

                ],
                sortOrder: [
                    { field: 'date_sortie_stock', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_sortie_stock_formatted',
                    'Magasin': 'nom_magasin',
                    'Total Montant HT': 'total_ht',
                    'Total Montant TVA': 'total_tva',
                    'Total Montant TTC': 'total_ttc',
                    'Commentaire': 'commentaire',
                    'Date Heure Enregistrement': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            motCle: "",
            crudform: {
                id: "",
                date_sortie_stock_formatted: "",
                magasin_id: "",
                commentaire: "",
                total_tva: "",
                total_ht: "",
                total_ttc: "",
                quantite_recuperable: "",
                clients_id: "",
                type_client: "INCONNU",
                nom_client_inconnu: "",
                details: []
            },
            crudmodaltitle: this.$t('traitement.sortie_stock.modal.nouveau_sortie_stock'),
            listdata: {
                familles: [],
                articles: [],
                magasins: [],
                clients: [],
                magasin_defaut: "",
            },
            criterearticle: {
                famille: "",
                search: ""
            },
            showDetail: true,
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },
            client: {},

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onClickInconnu: function() {
            this.crudform.type_client = 'INCONNU';
            this.crudform.nom_client_inconnu = "";
            this.crudform.clients_id = 0;
        },
        onClickClient: function() {
            this.crudform.type_client = 'CLIENT';
            this.crudform.nom_client_inconnu = "";
            console.log(this.crudform);
        },
        onChangeClient: function() {
            this.getClient(this.crudform.clients_id);
        },
        getClient: function(id_client) {
            if (id_client == '' || id_client == null) {
                this.client = {};
            } else {
                axios.get(this.BASE_URL + "/clients/get/" + id_client).then(response => {
                    this.client = response.data[0];

                });
            }

        },
        editRow(rowData) {
            this.crudmodaltitle = this.$t('traitement.sortie_stock.modal.editer_sortie_stock');
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/sortiestock/get/" + rowData.id).then(response => {
                //console.log(response.data[0]);
                this.crudmodaltitle = this.crudmodaltitle + " | " + response.data[0].num_bon_sortie;
                response.data[0].type_client = response.data[0].clients_id > 1 ? "CLIENT" : "INCONNU";
                this.crudform = response.data[0];
            });
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/sortiestock/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            var that = this;
            axios.post(that.BASE_URL + "/sortiestock/fetchmontanttotal", this.vuetable.moreParams).then(function(response) {
                console.log(response);
                that.montant_total = response.data[0].montantTotal;
            });
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },
        addRow() {
            this.crudmodaltitle = this.$t('traitement.sortie_stock.modal.nouveau_sortie_stock');
            this.showDetail = true;
            this.crudform = {
                id: "",
                date_sortie_stock_formatted: moment().format('DD/MM/YYYY'),
                magasin_id: "",
                commentaire: "",
                total_tva: "",
                total_ht: "",
                total_ttc: "",
                quantite_recuperable: "",
                clients_id: "",
                type_client: "INCONNU",
                nom_client_inconnu: "",
                details: []
            }
            if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                this.crudform.magasin_id = this.listdata.magasin_defaut + '';
            }
            this.openModal();
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                //console.log(response.data[0]);
                this.point_de_ventes = response.data;
            });
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = this.crudform.id == "" ? that.BASE_URL + "/sortiestock/addaction" : that.BASE_URL + "/sortiestock/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");

                                if (!(that.crudform.id > 0)) {
                                    var row = {
                                        id: response.data.id
                                    };
                                    that.editRow(row);
                                }

                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/sortiestock/fetchdata").then(response => {
                this.listdata.familles = response.data.familles;
                this.listdata.magasins = response.data.magasins;
                this.listdata.clients = response.data.clients;
                this.listdata.magasin_defaut = response.data.magasin_defaut;

                if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                    this.crudform.magasin_id = this.listdata.magasin_defaut + '';
                }
            });
        },
        setCritereArticleFilter: function() {
            axios.get(this.BASE_URL + "/articles/fetchlimitted", {
                params: {
                    famille: this.criterearticle.famille,
                    search: this.criterearticle.search,
                    magasin: this.crudform.magasin_id
                }
            }).then(response => {

                this.listdata.articles = response.data;
            });
        },
        resetCritereArticleFilter: function() {
            this.criterearticle.famille = "";
            this.criterearticle.search = "";


            this.setCritereArticleFilter();
        },
        pushrow: function(articleData) {
            console.log(articleData)
            var exist = false;
            for (var i = 0; i < this.crudform.details.length; i++) {
                var item = this.crudform.details[i];
                if (item.articles_id == articleData.articles_id) {
                    Vue.$toast.open({
                        message: "Cet article a déjà été choisit",
                        type: 'warning',
                        position: 'top-right'
                    });
                    exist = true;
                    break;
                }
            }


            if (!exist) {
                this.crudform.details.push({
                    articles_id: articleData.articles_id,
                    ref_article: articleData.ref_article,
                    designation: articleData.designation,
                    pau_ht: articleData.prix_revient,
                    quantite: ""
                });
            }
            // this.updateMontant();
        },
        delRow: function(i) {
            this.crudform.details.splice(i, 1);
            // this.updateMontant();
        },
        updateHT: function(i) {
            console.log("Update HT");
            var item = this.crudform.details[i];
            if (item.pau_ttc == '' || item.pau_ttc == null) {
                item.pau_ttc = 0;
            }
            if (item.tva == '' || item.tva == null) {
                item.tva = 0;
            }
            console.log(item);
            this.crudform.details[i].pau_ht = parseFloat(item.pau_ttc) / (parseFloat(item.tva) / 100 + 1);
            console.log(this.crudform.details[i].pau_ht);
            this.updateMontant();

        },
        updateTTC: function(i) {
            console.log("Update TTC");
            var item = this.crudform.details[i];
            if (item.pau_ht == '' || item.pau_ht == null) {
                item.pau_ht = 0;
            }
            if (item.tva == '' || item.tva == null) {
                item.tva = 0;
            }
            console.log(item);
            this.crudform.details[i].pau_ttc = parseFloat(item.pau_ht) * (parseFloat(item.tva) / 100 + 1);
            console.log(this.crudform.details[i].pau_ttc);
            this.updateMontant();

        },
        updateMontant: function() {
            this.crudform.total_a_payer = 0;
            this.crudform.total_ht = 0;
            this.crudform.total_ttc = 0;
            this.crudform.total_tva = 0;
            this.crudform.total_remise = 0;

            console.log("CHANGE: ");

            var montant_a_payer = 0;
            for (var i = 0; i < this.crudform.details.length; i++) {
                if (this.crudform.details[i].pau_ht == null || this.crudform.details[i].pau_ht == '') {
                    this.crudform.details[i].pau_ht = 0;
                }
                if (this.crudform.details[i].quantite == null || this.crudform.details[i].quantite == '') {
                    this.crudform.details[i].quantite = 0;
                }
                this.crudform.details[i].pau_ttc = parseFloat(this.crudform.details[i].pau_ht) * (parseFloat(this.crudform.details[i].tva) / 100 + 1);
                this.crudform.details[i].montant_ht = parseFloat(this.crudform.details[i].pau_ht) * parseFloat(this.crudform.details[i].quantite);
                this.crudform.details[i].montant_ttc = parseFloat(this.crudform.details[i].pau_ttc) * parseFloat(this.crudform.details[i].quantite);
                this.crudform.details[i].montant_tva = parseFloat(this.crudform.details[i].montant_ttc) - parseFloat(this.crudform.details[i].montant_tva);
                if (this.crudform.details[i].remise == '' || this.crudform.details[i].remise == null) {
                    this.crudform.details[i].remise = 0;
                }
                this.crudform.details[i].montant_a_payer = this.crudform.details[i].montant_ttc - this.crudform.details[i].remise;

                // Mettre a jour les totaux
                this.crudform.total_ht += this.crudform.details[i].montant_ht;
                this.crudform.total_ttc += this.crudform.details[i].montant_ttc;
                this.crudform.total_tva += this.crudform.details[i].montant_tva;
                this.crudform.total_remise += this.crudform.details[i].remise;
                montant_a_payer += this.crudform.details[i].montant_a_payer;

            }
            var remise = 0;
            if (this.crudform.remise != '' && this.crudform.remise != null) {
                remise = this.crudform.remise;
            }
            console.log(this.crudform.total_remise);
            console.log(remise);
            this.crudform.total_a_payer = montant_a_payer - parseFloat(remise);

        },

    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.fetchData();
        this.setCritereArticleFilter();
        this.loadPointDeVente();
        this.setFilter();

    },
    watch: {
        'criterearticle.famille': function() {
            this.setCritereArticleFilter();
        },
        'crudform.magasin_id': function() {
            this.setCritereArticleFilter();
        },

    },
    mounted: function() {
        // $("input").on("keydown", function(e) {
        //     e.preventDefault();
        //     return false;
        // });
        if (this.$route.params.type == 'add') {
            this.addRow();
        }
    }
}